.desktop-codeblock {
    display: block;
}

.mobile-responsive-codeblock {
    display: none;
}

.index-module_type__E-SaG {
    font-size: 1.1rem !important;
}

.flex-chips {
    line-height: 2;
    margin: 0.5;
}

.flex-chips .MuiChip-root {
    margin-right: 0.5em;
}

.flex-chips .MuiChip-outlined {
    background-color: #e0e0e0;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.flex-chips .MuiChip-outlined span {
    color: rgb(39, 40, 34);
}

.about-section {
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 5%;
}

.about-section .content {
    text-align: left;
    width: 100%;
}

.about-section .image-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.about-section .image-wrapper img{
    width: 80%;
}

.about p {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
}

.skills-container {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    text-align: left;

    svg {
        color: white;
    }
}
  
.skills-container .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
}
  
.skills-container .skills-grid .skill {
    align-items: center;
    text-align: left;
}
  
.chip {
    color: rgb(39, 40, 34) !important;
    background-color: white !important;
    font-size: 0.8rem !important;
    height: 25px !important;
  
    span {
        font-family: 'Courier Prime', monospace;
        color: rgb(39, 40, 34);
    }
  
    svg {
        height: 15px !important;
    }
}

.projects-container {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 5%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}
  
.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}
  
.projects-container .projects-grid .project {
    text-align: left;
}
  
.project h2:hover {
    text-decoration: underline;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
  
.zoom {
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
}
  
.zoom:hover {
    transform: scale(1.05);
}

footer {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    svg {
        width: 1.1em !important;
        height: 1.1em !important;
        margin-right: 10px;
    }

    p {
        font-size: 0.8em;
    }
}

@media screen {
    @media (max-width: 768px) {
        .skills-container {
            display: initial;
            padding-left: 5%;
            padding-right: 5%;
        }
    
        .skills-container .skills-grid {
            display: initial;
        }
        
        .skills-container .skills-grid .skill {
            padding-bottom: 5%;
        }
    }
    
    @media (max-width: 700px) {
        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }
  
        .projects-container .projects-grid {
            display: block;
        }
      
        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }
    }
    
    @media (max-width: 567px) {
        .nav-left{
            padding-top: 20px;
            float:left;
        }

        .nav-left img {
            height: 35px
        }

        .nav-right {
            float:right;
        }

        .nav-right li {
            padding: 10px 15px;
            list-style:none;
            font-size:13px;
            color:white;
            width: 10%;
        }

        .nav-right ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

        body {
            width: 100%;
        }

        .about {
            padding-left: 5%;
            padding-right: 5%;
            font-size: 12px;
        }

        .skills {
            font-size: 12px;
        }
    }

    @media (max-width:700px) {
        .desktop-codeblock {
            display: none;
        }
    
        .mobile-responsive-codeblock {
            display: block;
        }
    }

    @media (max-width:768px) {
        .about-section h1 {
            text-align: left;
        }

        .about-section p {
            text-align: left;
        }

        .container {
            display: initial;
        }

        .about-section {
            display: initial;
            padding-left: 0px;
            padding-right: 0px;
        }

        .about-section .content {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 5%;
        }

        .about-section .image-wrapper {
            width: 10%;
        }
    }
}
.about-page h2 {
    padding-top: 5%;
}

.vertical-timeline span {
    color: white;
    font-family: 'Courier Prime', monospace;
}

.vertical-timeline p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h3 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h4 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element span {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element .vertical-timeline-element-date {
    color: white;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 1170px) {
    .vertical-timeline-element .vertical-timeline-element-date {
        color: rgb(39, 40, 34);
    }
}
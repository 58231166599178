.about-page h2 {
    padding-top: 5%;
}

.vertical-timeline span {
    color: white;
    font-family: 'Courier Prime', monospace;
}

.vertical-timeline p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h3 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h4 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element span {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element .vertical-timeline-element-date {
    color: white;
}

.date-input {
  width: 200px;
}

.MuiFormLabel-root {
  color: white !important;
  font-family: 'Courier Prime', monospace !important;
}

.MuiInputBase-input {
  color: white !important;
  font-family: 'Courier Prime', monospace !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid white !important;
}

.submit-button {
  width: 100px;
  height: 30px;
  color: black;
  margin-top: 30px;
}

.dropdown-menu {
  width: 150px;
  height: 30px;
}

.items-container {
  color: white;
    font-family: 'Courier Prime', monospace;
}

.scrollable-table-in {
  position: absolute;
  width: 20%; // Adjust as needed
  height: 200px; // Adjust as needed
  overflow: auto;
  overflow-y: scroll;
  background-color: transparent;
  top: 150%;
  left: 35%;
  text-align: center;
}

.scrollable-table-in.loaded{
  background-color: white;
}

.scrollable-table-out {
  position: absolute;
  width: 20%; // Adjust as needed
  height: 200px; // Adjust as needed
  overflow: auto;
  overflow-y: scroll;
  background-color: transparent;
  top: 150%;
  left: 70%;
  text-align: center;
}

.scrollable-table-out.loaded{
  background-color: white;
}

.in-sample-image {
  position: absolute; // or relative, depending on your layout
  top: 95%; // Adjust as needed
  left: 20%; // Adjust as needed
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.out-sample-image {
  position: absolute; // or relative, depending on your layout
  top: 95%; // Adjust as needed
  left: 55%; // Adjust as needed
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.buffer{
  line-height: 400em;
}

.blurb{
  text-align: justify;
}

.Symbol {
  margin-top: 15px;
  margin-bottom: 1px;// Adjust as needed
  text-underline: white;
  //text-decoration: underline;
}

.loading-spinner {
  position: absolute; // Position fixed to the viewport
  top: 145%; // 50% from the top
  left: 18%; // 50% from the left
  transform: translate(-50%, -50%); // Translate back by 50% of its own width and height to center it
}

.navigation-bar {
  justify-content: space-between;
  background-color: rgb(39, 40, 34);

  button {
    font-size: 1.1rem;
    font-family: 'Courier Prime', monospace;
  }
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #acacac;
  transition: .4s;
  border-radius: 17px;
}

.custom-switch span:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff;
  transition: .3s;
  border-radius: 50%;
}

.custom-switch input:checked + span {
  background-color: #f32159;
}

.custom-switch input:checked + span:before {
  transform: translateX(26px);
}

.scrollable-table-in,
.scrollable-table-out {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* This will make the scrollbar transparent */
}

.scrollable-table-in.loaded,
.scrollable-table-out.loaded {
  scrollbar-color: auto; /* This will revert the scrollbar to the default appearance */
}

body {
  display: flex;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  min-height: 100vh;
}

.main-content {
  flex: 1; /* This will make the main content take up the remaining space */
  overflow-y: auto; /* Add vertical scrollbar */
}

.header-imag img {
  width: 100%; /* Make sure the image width is 100% to fill its container */
  height: 10%; /* Adjust the height as needed */
  object-fit: contain;
  //object-position: center; /* This will center the image within the container */
}


@media screen and (max-width: 1170px) {
    .vertical-timeline-element .vertical-timeline-element-date {
        color: rgb(39, 40, 34);
    }
}